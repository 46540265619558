import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDeleteBooking } from "../../../hooks/api/bookings";
import useGetStatusIcon from "../../../hooks/useGetStatusIcon";
import DeleteDialog from "../DeleteDialog";

const ListItem = ({ booking }) => {
  const {
    bookingId,
    status,
    name,
    startDate,
    endDate,
    estimatedArrival,
    users,
  } = booking;

  const StatusIcon = useGetStatusIcon(
    status,
    status === "OUT" ? endDate : startDate
  );

  const { mutate } = useDeleteBooking();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteButtonClick = (event) => {
    event.preventDefault();
    event.persist();
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();

    setIsDeleteModalOpen(true);
  };

  const handleDelete = (data = null) => {
    if (data) {
      mutate(bookingId);
    }

    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Link
        to={`/bookings/${bookingId}`}
        className="flex items-center justify-between px-8 py-2 border-b border-gray-200 hover:bg-gray-50 group"
      >
        <div className="flex items-center justify-center flex-none w-8 h-8 text-teal-600 border border-teal-600 rounded-lg cursor-pointer group-hover:bg-teal-600 group-hover:text-white">
          <StatusIcon className="w-5 h-5" />
        </div>
        <span className="h-6 mx-6 border-r border-gray-200"></span>
        <div className="flex flex-col flex-1 leading-none">
          <span className="text-sm font-medium text-bluegray-500">{name}</span>
          <span className="font-thin text-bluegray-700">{bookingId}</span>
        </div>
        <div className="flex flex-col flex-1 ml-4 leading-none">
          <span className="text-sm font-medium text-bluegray-500">
            Bokningsperiod
          </span>
          <span className="font-thin text-bluegray-700">
            {`${new Date(startDate).toLocaleDateString("sv-SE", {
              month: "short",
              day: "numeric",
            })} - ${new Date(endDate).toLocaleDateString("sv-SE", {
              month: "short",
              day: "numeric",
            })}`}
          </span>
        </div>
        <div className="flex flex-col flex-1 ml-4 leading-none">
          <span className="text-sm font-medium text-bluegray-500">
            Beräknad ankomst
          </span>
          <span className="font-thin text-bluegray-700">
            {estimatedArrival}
          </span>
        </div>
        <div className="flex flex-col flex-1 ml-4 leading-none">
          <span className="text-sm font-medium text-bluegray-500">
            Antal personer
          </span>
          <span className="font-thin text-bluegray-700">{users.length}</span>
        </div>
        <FontAwesomeIcon
          className="cursor-pointer hover:text-red-800"
          icon={faTrashAlt}
          onClick={handleDeleteButtonClick}
        />
      </Link>
      <DeleteDialog
        isOpen={isDeleteModalOpen}
        closeModal={handleDelete}
        confirmText={`                      <span>
          Är du säker på att du vill ta bort bokningen med
          bokningsnummer&nbsp;
        </span>
        <strong>${bookingId}.</strong>
        <span>
          &nbsp;Eventuellt sparad data kommer att gå förlorad
        </span>`}
      />
    </>
  );
};

export default ListItem;
