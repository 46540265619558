import React from "react";
import { useParams } from "react-router-dom";
import { useGetBooking, useUpdateBooking } from "../../hooks/api/bookings";
import Spinner from "../Spinner";
import BookingDetailsHeader from "./BookingDetailsHeader";
import BookingDetailsBody from "./BookingDetailsBody";

const BookingDetails = () => {
  const { bookingId } = useParams();

  const { data, isLoading } = useGetBooking(bookingId);

  const { mutate } = useUpdateBooking();

  const handleUpdate = (key, value) => {
    data[key] = value;
    mutate(data);
  };

  return (
    <div className="flex w-full h-full">
      {isLoading && <Spinner />}

      {!isLoading && data && (
        <div className="flex flex-col w-full h-full px-4">
          <BookingDetailsHeader data={data} handleUpdate={handleUpdate} />
          <BookingDetailsBody
            users={data.users}
            gear={data.gear}
            handleUpdate={handleUpdate}
          />
        </div>
      )}
    </div>
  );
};

export default BookingDetails;
