import React from "react";
import { Link } from "react-router-dom";

const DashboardCardItem = ({ booking }) => {
  const { bookingId, name } = booking;

  return (
    <Link
      to={`/bookings/${bookingId}`}
      className="flex w-full p-2 border-t border-gray-300 hover:bg-gray-100"
    >
      <p className="font-medium">{name}</p>&nbsp;-&nbsp;
      <p className="font-light text-gray-400">{bookingId}</p>
    </Link>
  );
};

export default DashboardCardItem;
