import React from "react";
import { AppContextProvider } from "../../context/AppContext";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

const Layout = ({ path, children }) => {
  return (
    <AppContextProvider>
      <div className="relative flex w-full h-screen overflow-hidden">
        <Sidebar />
        <div className="flex flex-col w-full h-full">
          <Topbar path={path} />
          <div className="h-full overflow-x-auto">{children}</div>
        </div>
      </div>
    </AppContextProvider>
  );
};

export default Layout;
