import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import logo from "../images/logo.png";

const Login = () => {
  const { changeAuth, user } = useContext(AuthContext);
  const history = useHistory();
  useEffect(() => {
    chechAuth();
  }, []);

  const chechAuth = () => {
    if (user) {
      history.push("/");
    }
  };

  const [formData, setformData] = useState({
    email: "",
    password: "",
    error: null,
  });

  const { email, password } = formData;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          email,
          password,
        }
      );

      const { token } = res.data;
      changeAuth(token);
      history.push("/");
    } catch (error) {
      setformData({
        ...formData,
        error: "Felaktigt användarnamn eller lösenord",
      });
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col p-4 bg-white border border-gray-300 rounded"
      >
        <img src={logo} alt="Logo" className="w-1/5 h-auto m-auto mb-4" />

        <input
          className="border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent focus:ring-opacity-50"
          type="text"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) =>
            setformData({ ...formData, [e.target.name]: e.target.value })
          }
        />
        <input
          className="my-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent focus:ring-opacity-50"
          type="password"
          name="password"
          placeholder="Lösenord"
          value={password}
          onChange={(e) =>
            setformData({ ...formData, [e.target.name]: e.target.value })
          }
        />
        <button className="px-1 py-2 font-bold text-white bg-teal-600 rounded">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
