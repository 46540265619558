import React from "react";
import { Link } from "react-router-dom";

const TopBarSearchResultItem = ({ booking, close }) => {
  const { bookingId, name, socialSecurity, startDate, endDate, users } =
    booking;

  return (
    <Link
      onClick={() => close()}
      to={`/bookings/${bookingId}`}
      className="flex items-center p-4 mb-2 rounded-lg bg-bluegray-100 hover:bg-teal-600 hover:text-white"
    >
      <div className="flex flex-col flex-1">
        <h2 className="font-medium">{name}</h2>
        <span className="flex-1 font-light">{socialSecurity}</span>
      </div>
      <div className="flex flex-col flex-1">
        <h2 className="font-medium">Bokningsperiod</h2>
        <span>
          {new Date(startDate).toLocaleDateString("sv-SE", {
            month: "short",
            day: "numeric",
          })}
          &nbsp;-&nbsp;
          {new Date(endDate).toLocaleDateString("sv-SE", {
            month: "short",
            day: "numeric",
          })}
        </span>
      </div>
      <div className="flex flex-col flex-1">
        <h2 className="font-medium">Antal personer</h2>
        <span className="flex-1 font-light">{users.length}</span>
      </div>
    </Link>
  );
};

export default TopBarSearchResultItem;
