import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.png";
import { AppContext } from "../../context/AppContext";

const Sidebar = () => {
  const { menuOpen, setMenuOpen } = useContext(AppContext);

  return (
    <div
      className={`w-80 bg-bluegray-800 shadow-lg flex-col pl-8 transform ${
        menuOpen ? "flex z-50 absolute h-full" : "hidden"
      } xl:relative xl:flex `}
    >
      {menuOpen && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 ml-auto text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={() => setMenuOpen(false)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      )}
      <div className="flex items-center justify-center w-20 h-20 mx-auto mt-4 mb-8 bg-white rounded-full">
        <img src={logo} alt="Logo" className="w-3/5 h-auto " />
      </div>

      <NavLink
        to="/"
        exact
        activeClassName="bg-teal-600 rounded-l-full"
        className="flex items-center py-2 pl-4 pr-8 font-medium text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 mr-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
          />
        </svg>
        Dashboard
      </NavLink>
      <p className="my-2 text-gray-400">Bokningar</p>
      <NavLink
        activeClassName="bg-teal-600 rounded-l-full"
        className="flex items-center py-2 pl-4 pr-8 font-medium text-white"
        to="/bookings"
        exact
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 mr-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </svg>
        Aktiva bokningar
      </NavLink>
      <NavLink
        activeClassName="bg-teal-600 rounded-l-full"
        className="flex items-center py-2 pl-4 pr-8 font-medium text-white"
        to="/bookings/all"
        exact
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 mr-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </svg>
        Alla bokningar
      </NavLink>
      <NavLink
        activeClassName="bg-teal-600 rounded-l-full"
        className="flex items-center py-2 pl-4 pr-8 font-medium text-white"
        to="/bookings/create"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 mr-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 4v16m8-8H4"
          />
        </svg>
        Skapa bokning
      </NavLink>
    </div>
  );
};

export default Sidebar;
