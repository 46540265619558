import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Transition } from "@headlessui/react";
import React, { useState, useEffect, Fragment } from "react";
import api from "../../utils/api";
import TopBarSearchInput from "./TopBarSearchInput";
import TopBarSearchResultItem from "./TopBarSearchResultItem";

const TopBarSearch = () => {
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timeOutId = search(searchText);
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  const search = () => {
    if (searchText.length >= 3) {
      setIsLoading(true);
      const timeOutId = setTimeout(async () => {
        const res = await api.get("/bookings/search", {
          params: { searchText },
        });

        setSearchResult(res.data);
        setIsLoading(false);
      }, 1000);

      return timeOutId;
    }
  };

  return (
    <Popover className="relative flex w-full">
      {({ open, close }) => (
        <>
          <Popover.Button className="ml-auto">
            <FontAwesomeIcon icon={faSearch} />
          </Popover.Button>

          {open && (
            <Transition
              appear
              show={open}
              as={"div"}
              className="fixed inset-0 z-10"
            >
              <Transition.Child
                as="div"
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 z-10 w-screen h-screen bg-bluegray-700 opacity-70"></div>
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  static
                  className="absolute z-20 w-4/5 transform -translate-x-1/2 bg-white border border-gray-300 rounded shadow-lg top-16 left-1/2 "
                >
                  <div className="flex items-center p-4">
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="flex w-10 h-10 pr-4 mr-4 text-4xl text-gray-300 border-r border-gray-300"
                    />
                    <TopBarSearchInput handleInput={(e) => setSearchText(e)} />
                  </div>
                  {searchText.length >= 3 && (
                    <div className="flex flex-col w-full p-4 border-t border-gray-300">
                      {isLoading && (
                        <span className="m-auto throbber-loader">
                          Loading...
                        </span>
                      )}
                      {!isLoading && searchResult && !searchResult.length && (
                        <p className="m-auto font-medium">
                          Kunde inte hitta bokning...
                        </p>
                      )}
                      {!isLoading &&
                        searchResult &&
                        searchResult.length > 0 &&
                        searchResult.map((booking) => (
                          <TopBarSearchResultItem
                            close={close}
                            booking={booking}
                            key={booking.bookingId}
                          />
                        ))}
                    </div>
                  )}
                </Popover.Panel>
              </Transition.Child>
            </Transition>
          )}
        </>
      )}
    </Popover>
  );
};

export default TopBarSearch;
