import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import CustomRoute from "./components/CustomRoute";

import { AuthContextProvider } from "./context/AuthContext";
import routes from "./utils/routes";

function App() {
  const queryClient = new QueryClient();

  return (
    <div className="text-bluegray-600 bg-bluegray-200">
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <Router>
            <Switch>
              {routes.map(({ isProtected, path, title, Component }, i) =>
                isProtected ? (
                  <CustomRoute key={i} exact path={path} title={title}>
                    <Component />
                  </CustomRoute>
                ) : (
                  <Route key={i} exact path={path}>
                    <Component />
                  </Route>
                )
              )}
            </Switch>
          </Router>
        </AuthContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
