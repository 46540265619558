import React from "react";
import { useGetInfo } from "../hooks/api/bookings";
import Spinner from "./Spinner";
import DashboardCard from "./UI/Card/DashboardCard";
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Dashboard = () => {
  const { data, isLoading } = useGetInfo();

  if (isLoading) return <Spinner />;

  return (
    <div className="flex flex-col p-4">
      {!isLoading && data && (
        <>
          <Link
            className="flex items-center pr-3 mb-4 ml-auto text-teal-600"
            to="/bookings/create"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            <span className="font-bold">Skapa bokning</span>
          </Link>
          <div className="flex space-x-6">
            <DashboardCard
              title="Hämtas"
              icon={faArrowRight}
              text={`av totalt ${data.new} ej utlämnade`}
              count={data.outgoing.length}
              bookings={data.outgoing}
            />
            <DashboardCard
              title="Lämnas"
              icon={faArrowLeft}
              text={`av totalt ${data.out} utlämnade`}
              count={data.incoming.length}
              bookings={data.incoming}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
