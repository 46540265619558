import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Layout from './layout/Layout';

const CustomRoute = ({ children, path, ...rest }) => {
  const { user, isAuthLoading } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthLoading ? (
          <p>Loading...</p>
        ) : user ? (
          <Layout path={path}>{children}</Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default CustomRoute;
