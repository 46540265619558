import { PlusIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetActiveBookings } from "../../hooks/api/bookings";
import Spinner from "../Spinner";
import Card from "../UI/Card/Card";
import ListItem from "../UI/List/ListItem";

const getDefaultRangeValue = () => {
  const value = sessionStorage.getItem("bookings-range");

  if (value) {
    const [start, end] = JSON.parse(value)
    
    if (start && end) {
      return [new Date(start), new Date(end)]
    }
    
  }

  return [null, null]
}

const getDefaultBookingTypeValue = () => {
  const value = sessionStorage.getItem("booking-type");

  return value ?? "ALL"
}

const Bookings = () => {
  const [searchText, setSearchText] = useState("");
  const [bookingType, setBookingType] = useState(getDefaultBookingTypeValue);
  const [range, setRange] = useState(getDefaultRangeValue());

  const history = useHistory();

  const onRangeUpdate = (update) => {
    sessionStorage.setItem("bookings-range", JSON.stringify(update))
    
    setRange(update)
    
  }

  const onBookingTypeUpdate = (bookingType) => {
    sessionStorage.setItem("booking-type", bookingType);

    setBookingType(bookingType)
  
  }

  const { data, isLoading } = useGetActiveBookings();

  const filterFn = (row) => {
    let found = false;
    Object.keys(row).forEach((key) => {
      if (
        row[key] &&
        row[key].toString().toLowerCase().includes(searchText.toLowerCase())
      )
        found = true;
    });

    return found;
  };

  const statusFilterFn = (row) => {
    if (bookingType === "ALL") {
       return true;
    }

    return bookingType === row.status
  }

  const dateFilterFn = (row) => {
    if (!range || !(range[0] && range[1])) {
      return true
    }

    return (new Date(row.startDate) >= new Date(range[0]) && new Date(row.startDate) <= new Date(range[1]))


  }

  const onCardButtonClick = () => {
    history.push("/bookings/create");
  };

  const filteredData = !isLoading && data ? data
  .filter((x) => filterFn(x))
  .filter((x) => statusFilterFn(x))
  .filter((x) => dateFilterFn(x)) : [];


  return (
    <div className="flex flex-1 w-full h-full overflow-hidden">
      {isLoading && <Spinner />}
      {!isLoading && data && (
        <Card
          title="Aktiva bokningar"
          isSearchActive="true"
          setSearchText={setSearchText}
          searchText={searchText}
          onBookingTypeUpdate={onBookingTypeUpdate}
          bookingType={bookingType}
          isButtonActive="true"
          buttonText="Skapa bokning"
          ButtonIcon={PlusIcon}
          onClick={onCardButtonClick}
          range={range}
          onRangeUpdate={onRangeUpdate}
          showFilters={true}
          totalUsers={filteredData?.reduce((sum, x) => sum + x.users.length, 0) ?? 0}
        >
          {filteredData.map((booking) => (
            <ListItem key={booking.bookingId} booking={booking} />
          ))}
        </Card>
      )}
    </div>
  );
};

export default Bookings;
