import { useQueryClient, useMutation, useQuery } from "react-query";
import api from "../../utils/api";

export const useGetBooking = (bookingId) =>
  useQuery(`bookings/${bookingId}`, async () => {
    const { data } = await api.get(`bookings/${bookingId}`);
    return data;
  });

export const useGetBookings = () =>
  useQuery(`bookings`, async () => {
    const { data } = await api.get(`bookings`);
    return data;
  });

export const useGetActiveBookings = () =>
  useQuery(`bookings/active`, async () => {
    const { data } = await api.get(`bookings/active`);
    return data;
  });

export const useGetInfo = () =>
  useQuery(
    `bookings/info`,
    async () => {
      const { data } = await api.get("bookings/info");
      return data;
    },
    { cacheTime: 0 }
  );

export const useUpdateBooking = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (booking) => {
      const { data } = await api.put(`bookings`, booking);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bookings");
        queryClient.invalidateQueries("bookings/active");
      },
    }
  );
};

export const useDeleteBooking = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (bookingId) => {
      const { data } = await api.delete(`bookings/${bookingId}`);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bookings");
        queryClient.invalidateQueries("bookings/active");
      },
    }
  );
};
