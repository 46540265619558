import React from 'react';

const Spinner = () => {
  return (
    <div className='w-full h-full flex items-center justify-center'>
      <div className='sp sp-slices w-16 h-16'></div>
    </div>
  );
};

export default Spinner;
