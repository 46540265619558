import React, { useEffect, useRef } from "react";

const TopBarSearchInput = ({ handleInput }) => {
  const input = useRef();

  useEffect(() => {
    input.current.focus();
  }, []);

  return (
    <input
      ref={input}
      onChange={(e) => handleInput(e.target.value)}
      type="text"
      placeholder="Sök på bokningsnummer eller personnummer..."
      className="w-full border-none focus:outline-none focus:ring-transparent focus:border-b focus:border-teal-600"
    />
  );
};

export default TopBarSearchInput;
