import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardCardItem from "./DashboardCardItem";
const DashboardCard = ({ title, icon, count, text, bookings }) => {
  return (
    <div className="flex flex-col flex-1 overflow-hidden bg-white border border-gray-300 rounded-xl">
      <p className="px-4 pt-2 font-bold text-bluegray-400">{title}</p>
      <div className="flex items-center p-4">
        <div className="px-4 py-3 mr-6 bg-teal-100 rounded-full">
          <FontAwesomeIcon className="text-2xl text-teal-600" icon={icon} />
        </div>
        <div className="flex flex-col leading-none">
          <span className="text-3xl font-thin leading-none text-bluegray-800">
            {count}
          </span>
          <span className="text-lg font-thin leading-none text-bluegray-400">
            {text}
          </span>
        </div>
      </div>
      <div className="flex flex-col">
        {bookings.map((booking) => (
          <DashboardCardItem key={booking.bookingId} booking={booking} />
        ))}
      </div>
    </div>
  );
};

export default DashboardCard;
