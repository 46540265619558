import React, { useState } from "react";
import { useForm } from "react-hook-form";

const HeaderItem = ({ rowKey, data, label, handleUpdate }) => {
  const [isEdit, setIsEdit] = useState(false);

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    setFocus,
  } = useForm({});

  const handleClick = () => {
    if (isEdit) return;

    reset({ [rowKey]: data[rowKey] });
    setIsEdit(true);
    setTimeout(() => {
      setFocus(rowKey);
    }, 10);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsEdit(false);
    }, 100);
  };

  const handleEscape = ({ keyCode }) => keyCode === 27 && setIsEdit(false);

  return (
    <div
      className="flex flex-col flex-1 cursor-pointer group"
      onClick={handleClick}
    >
      <h3 className="font-medium">{label}</h3>
      {!isEdit && (
        <p className="font-thin group-hover:text-teal-600">{data[rowKey]}</p>
      )}
      {isEdit && (
        <form
          className="relative w-48"
          onSubmit={handleSubmit((data) => {
            handleUpdate(rowKey, data[rowKey]);
            setIsEdit(false);
          })}
        >
          <input
            {...register(rowKey)}
            type="text"
            className="w-48 pb-2 header-item-input"
            onBlur={() => handleBlur()}
            onKeyDown={handleEscape}
          />
          <button
            type="submit"
            className="absolute right-0 px-1 py-1 mt-1 text-sm font-medium text-gray-300 border border-gray-200 rounded"
          >
            ENT
          </button>
        </form>
      )}
    </div>
  );
};

export default HeaderItem;
