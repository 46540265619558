import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "@headlessui/react";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { KeyIcon } from "@heroicons/react/outline";
import ChangePasswordDialog from "./ChangePasswordDialog";

const TopBarUserMenu = () => {
  const { user, changeAuth } = useContext(AuthContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleChangePassword = (data) => {
    if (data) {
      console.log(data);
    }

    setIsDialogOpen(false);
  };

  return (
    <>
      <Menu as="div" className="relative flex-none ml-4">
        <Menu.Button className="flex items-center">
          <FontAwesomeIcon icon={faUserCircle} className="text-xl" />

          <span className="hidden ml-2 lg:flex lg:visible">{user.name}</span>
        </Menu.Button>
        <Menu.Items className="absolute z-20 w-full bg-white border border-gray-300 rounded shadow-lg top-8">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => setIsDialogOpen(true)}
                type="button"
                className="flex items-center w-full p-4 border-b border-gray-300 hover:bg-gray-50"
              >
                <KeyIcon className="w-6 h-6" />
                <span className="ml-2">Byt lösenord</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => changeAuth(null)}
                type="button"
                className="flex items-center w-full p-4 hover:bg-gray-50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
                <span className="ml-2">Logga ut!</span>
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
      <ChangePasswordDialog
        isOpen={isDialogOpen}
        closeModal={handleChangePassword}
      />
    </>
  );
};

export default TopBarUserMenu;
