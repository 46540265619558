import React from "react";
import api from "../../utils/api";
import { useHistory } from "react-router";
import CustomInput from "../CustomInput";
import { Controller, useForm } from "react-hook-form";
import Card from "../UI/Card/Card";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import { SaveIcon } from "@heroicons/react/outline";

registerLocale("sv", sv);
setDefaultLocale("sv");
const CreateBooking = () => {
  const history = useHistory();
  const schema = yup.object().shape({
    name: yup.string().required("Test"),
    socialSecurity: yup.string().required(),
    address: yup.string().required(),
    zipCode: yup.string().required(),
    city: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    startDate: yup.date().required(),
    endDate: yup.date().required(),
    estimatedArrival: yup.string(),
  });

  const {
    control,
    getValues,
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitBooking = async (data) => {
    delete data.dateRange;
    const res = await api.post("/bookings", data);

    const { bookingId } = res.data;

    history.push(`/bookings/${bookingId}`);
  };

  return (
    <form className="flex" onSubmit={handleSubmit(submitBooking)}>
      <Card
        title="Skapa boknng"
        isButtonActive="true"
        buttonText="Spara"
        buttonType="submit"
        ButtonIcon={SaveIcon}
      >
        <div className="flex flex-col px-8 py-4">
          <CustomInput
            type="text"
            name="name"
            label="Namn"
            register={register}
            errors={errors}
          />
          <CustomInput
            type="text"
            name="socialSecurity"
            label="Personnummer"
            placeholder="YYYYMMDDXXXX"
            register={register}
            errors={errors}
          />
          <CustomInput
            type="text"
            name="address"
            label="Adress"
            register={register}
            errors={errors}
          />
          <CustomInput
            type="text"
            name="zipCode"
            label="Postnummer"
            register={register}
            errors={errors}
          />
          <CustomInput
            type="text"
            name="city"
            label="Ort"
            register={register}
            errors={errors}
          />
          <CustomInput
            type="text"
            name="email"
            label="E-Post"
            register={register}
            errors={errors}
          />
          <CustomInput
            type="text"
            name="phone"
            label="Telefonnummer"
            register={register}
            errors={errors}
          />
          <div className="flex flex-col">
            <span className="font-medium">Uthyrningsperiod</span>
            <Controller
              name="dateRange"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <DatePicker
                  selectsRange={true}
                  startDate={getValues("startDate")}
                  endDate={getValues("endDate")}
                  onChange={(update) => {
                    field.onChange(update);
                    const [updatedStart, updatedEnd] = update;
                    setValue("startDate", updatedStart);
                    setValue("endDate", updatedEnd);
                  }}
                  locale={sv}
                  calendarStartDay={1}
                  dateFormat="yyyy-MM-dd"
                  withPortal
                  customInput={
                    <input
                      type="text"
                      className={`flex-1 w-full mt-1 border-gray-300 rounded-lg shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-30 ${
                        errors.startDate && "border-red-300"
                      }`}
                    />
                  }
                />
              )}
            />
            {errors.startDate && (
              <span className="text-red-500">{errors.startDate.message}</span>
            )}
          </div>
          <CustomInput
            type="text"
            name="estimatedArrival"
            label="Ungefärlig ankomsttid"
            register={register}
            errors={errors}
          />
        </div>
      </Card>
    </form>
  );
};

export default CreateBooking;
