// eslint-disable-next-line import/no-anonymous-default-export
export default (dateString) => {
  const date = new Date(dateString);

  const now = new Date();

  return (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
};
