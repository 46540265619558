export const gearTypes = [
  { value: "", text: "-- Välj typ --" },
  { value: "Multihyr", text: "Multihyr" },
  { value: "Alpint - Nybörjare", text: "Alpint - Nybörjare" },
  { value: "Alpint - Mellan/Twintips", text: "Alpint - Mellan/Twintips" },
  { value: "Alpint - Topp", text: "Alpint - Topp" },
  { value: "Blades", text: "Blades" },
  { value: "Snowboard", text: "Snowboard" },
  { value: "Längd - Vallningsfritt", text: "Längd - Vallningsfritt" },
  {
    value: "Längd - Vallningsbart / Skins",
    text: "Längd - Vallningsbart  / Skins",
  },
  { value: "Längd - Fjällpulka", text: "Längd - Fjällpulka" },
];
