import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import CustomInput from "../CustomInput";
import { useChangePassword } from "../../hooks/api/auth";

const ChangePasswordDialog = ({ isOpen, closeModal }) => {
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsloading(false);
      reset();
    }
  }, [isOpen]);

  const schema = yup.object().shape({
    password: yup.string().required("Password is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const useHandleChangePassword = async ({ password }) => {
    setIsloading(true);
    await useChangePassword(password);
    closeModal();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <form
          onSubmit={handleSubmit(useHandleChangePassword)}
          className="min-h-screen px-4 text-center"
        >
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-bluegray-800 opacity-70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="flex items-center px-6 py-2 text-lg font-medium leading-6"
              >
                Byt lösenord
              </Dialog.Title>

              <div className="flex flex-col px-6 mt-2 mb-4">
                {isLoading && (
                  <span className="m-auto throbber-loader">Loading...</span>
                )}
                {!isLoading && (
                  <>
                    <CustomInput
                      type="password"
                      name="password"
                      label="Nytt lösenord"
                      register={register}
                      errors={errors}
                    />
                    <CustomInput
                      type="password"
                      name="passwordConfirmation"
                      label="Bekräfta lösenord"
                      register={register}
                      errors={errors}
                    />
                  </>
                )}
              </div>
              <div className="flex items-center justify-end px-6 py-3 bg-gray-50">
                <button
                  type="button"
                  className="px-4 py-2 text-base font-medium border border-gray-300 rounded"
                  onClick={() => closeModal()}
                >
                  Avbryt
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 ml-2 text-base font-medium text-white bg-teal-600 rounded"
                >
                  Spara
                </button>
              </div>
            </div>
          </Transition.Child>
        </form>
      </Dialog>
    </Transition>
  );
};

export default ChangePasswordDialog;
