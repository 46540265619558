import React from "react";
import DatePicker from "react-datepicker"
import sv from "date-fns/locale/sv";
import { registerLocale, setDefaultLocale } from "react-datepicker";

registerLocale("sv", sv);
setDefaultLocale("sv");

const Card = ({
  title,
  isSearchActive,
  searchText,
  setSearchText,
  bookingType,
  onBookingTypeUpdate,
  isButtonActive,
  buttonText,
  buttonType,
  ButtonIcon,
  onClick,
  children,
  range,
  onRangeUpdate,
  totalUsers,
  showFilters = false
}) => {

  const [stateStart, stateEnd] = range ?? []

  return (
    <div className="relative flex flex-col w-full h-auto m-4 overflow-hidden bg-white border border-gray-300 rounded shadow-lg">
      <div className="flex items-center flex-none border-b border-gray-300">
        <span className="p-4 text-2xl font-thin text-bluegray-800">
          {title}
        </span>
        {((isSearchActive || isButtonActive) && showFilters) && (
          <div className="flex items-center h-full ml-auto">
            {isSearchActive && (
              <div className="flex items-center gap-8 mr-4">
                <input
                  className="h-4 px-6 py-5 ml-auto border border-gray-300 rounded-full bg-bluegray-200"
                  type="text"
                  placeholder="Sök"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  />
                <select value={bookingType} onChange={(e) => onBookingTypeUpdate(e.target.value)} className="w-48 h-10 px-6 border border-gray-300 rounded-full bg-bluegray-200">
                  <option value="ALL">Alla</option>
                  <option value="OUT">Inkomande</option>
                  <option value="NEW">Utgående</option>
                </select>
                <DatePicker
                  className="h-4 w-72 px-4 py-5 border border-gray-300 rounded-full bg-bluegray-200"
                  placeholderText="Välj datum"
                  selectsRange={true}
                  startDate={stateStart}
                  endDate={stateEnd}
                  onChange={onRangeUpdate}
                  locale={sv}
                  dateFormat="yyyy-MM-dd"
                  withPortal
                  calendarStartDay={1}
                  isClearable
                />
              </div>
            )}
            {isButtonActive && (
              <button
                type={buttonType || "button"}
                className="flex items-center h-full p-4 -mt-4 -mb-4 border-l border-gray-300 hover:bg-bluegray-200"
                onClick={onClick}
              >
                {ButtonIcon && (
                  <ButtonIcon className="flex-none w-4 h-4 mr-2" />
                )}
                {buttonText}
              </button>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col flex-1 overflow-auto">{children}</div>
      { (stateStart && stateEnd) &&
        <div className="w-full justify-end flex ml-auto p-2 border-t border-gray-300">
          <div>
            <strong>Totalt antal personer: </strong> {totalUsers}
          </div>
        </div>
      }
    </div>
  );
};

export default Card;
