import AllBookings from "../components/bookings/AllBookings";
import BookingDetails from "../components/bookings/BookingDetails";
import Bookings from "../components/bookings/Bookings";
import CreateBooking from "../components/bookings/CreateBooking";
import Dashboard from "../components/Dashboard";
import Login from "../components/Login";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  { path: "/login", title: "Login", Component: Login, isProtected: false },
  {
    path: "/",
    title: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
</svg>`,
    Component: Dashboard,
    isProtected: true,
  },
  {
    path: "/bookings",
    title: "Bokningar",
    Component: Bookings,
    isProtected: true,
  },
  {
    path: "/bookings/all",
    title: "Alla",
    Component: AllBookings,
    isProtected: true,
  },
  {
    path: "/bookings/create",
    title: "Skapa bokning",
    Component: CreateBooking,
    isProtected: true,
  },
  {
    path: "/bookings/:bookingId",
    title: "bookingId",
    Component: BookingDetails,
    isProtected: true,
  },
];
