import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import AddUserDialog from "./AddUserDialog";
import DeleteDialog from "../UI/DeleteDialog";

const BookingUser = ({ user, index, handleDelete, handleEdit }) => {
  const { name, gearType, age, length, weight, shoeSize, additionalInfo } =
    user;

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleEditClose = (data) => {
    console.log(data);
    if (data) handleEdit(data, index);

    setIsEditDialogOpen(false);
  };

  const handleDeleteClose = (data) => {
    if (data) handleDelete(index);

    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      <div className="flex flex-wrap w-full h-auto p-4 border-b border-gray-300">
        <div className="flex flex-col flex-1">
          <h3 className="font-bold">{name}</h3>
          <p>{gearType}</p>
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="font-bold">Ålder</h3>
          <p>{age}</p>
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="font-bold">Längd</h3>
          <p>{length}</p>
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="font-bold">Vikt</h3>
          <p>{weight}</p>
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="font-bold">Skostorlek</h3>
          <p>{shoeSize}</p>
        </div>
        <div className="flex items-center ml-auto">
          <PencilAltIcon
            className="w-6 h-6 cursor-pointer hover:text-teal-600"
            onClick={() => setIsEditDialogOpen(true)}
          />
          <TrashIcon
            onClick={() => setIsDeleteDialogOpen(true)}
            className="w-6 h-6 ml-4 cursor-pointer hover:text-red-700"
          />
        </div>
        {additionalInfo && (
          <div className="flex flex-col w-full mt-4">
            <h3 className="font-bold">Övrig information</h3>
            <p>{additionalInfo}</p>
          </div>
        )}
      </div>
      <AddUserDialog
        isOpen={isEditDialogOpen}
        editData={{
          name,
          gearType,
          age,
          length,
          weight,
          shoeSize,
          additionalInfo,
        }}
        closeModal={handleEditClose}
      />
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        closeModal={handleDeleteClose}
        confirmText={`<span>
          Är du säker på att du vill ta bort användaren med
          namn&nbsp;
        </span>
        <strong>${name}.</strong>
        <span>
          &nbsp;Eventuellt sparad data kommer att gå förlorad
        </span>`}
      />
    </>
  );
};

export default BookingUser;
