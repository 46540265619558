import {
  AnnotationIcon,
  ArrowSmLeftIcon,
  ArrowSmRightIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";
import api from "../../utils/api";
import useGetStatusIcon from "../../hooks/useGetStatusIcon";
import BookingDetailsMenu from "./BookingDetailsMenu";
import HeaderItem from "./HeaderItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import NotesDialog from "./NotesDialog";
import BookingStatusDialog from "./BookingStatusDialog";
import daysBetween from "../../utils/daysBetween";
registerLocale("sv", sv);
setDefaultLocale("sv");

const BookingDetailsHeader = ({ data, handleUpdate }) => {
  const { bookingId, status, startDate, endDate, notes, createdAt } = data;

  const [range, setRange] = useState([new Date(startDate), new Date(endDate)]);
  const [stateStart, stateEnd] = range;

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);

  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);

  const StatusIcon = useGetStatusIcon(
    status,
    status === "OUT" ? endDate : startDate
  );

  const handleNotesDialogClose = (data) => {
    if (data) {
      handleUpdate("notes", data.notes);
    }

    setIsNotesDialogOpen(false);
  };

  const handleStatusDialogClose = (data) => {
    if (data) {
      handleUpdate("status", data.status);
    }

    setIsStatusDialogOpen(false);
  };

  const firstRowItems = [
    { rowKey: "name", label: "Namn" },
    { rowKey: "socialSecurity", label: "Personnummer" },
    { rowKey: "phone", label: "Telefonnummer" },
    { rowKey: "email", label: "E-post" },
  ];

  const secondRowItems = [
    { rowKey: "address", label: "Adress" },
    { rowKey: "zipCode", label: "Postnummer" },
    { rowKey: "city", label: "Ort" },
    { rowKey: "estimatedArrival", label: "Beräknad ankomst" },
  ];

  const downloadBookingPdf = async () => {
    const { data } = await api.get(`bookings/${bookingId}/download`, {
      responseType: "blob",
    });

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = `${bookingId}.pdf`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const isNewPrice = () =>
    new Date(data.createdAt).getFullYear() >=
    new Date("2023-01-01").getFullYear();

  return (
    <>
      <div className="flex flex-col flex-none w-full p-6 mt-4 bg-white border border-b-0 border-gray-300 rounded-lg rounded-b-none">
        <div className="flex items-center mb-6">
          <div className="p-2 mr-4 text-white bg-teal-600 rounded-lg">
            <StatusIcon className="w-6 h-6 " />
          </div>
          <h2 className="mr-2 text-2xl">{`#${bookingId}`}</h2>
          <p className="font-extrabold">&nbsp;|&nbsp;</p>
          <span
            className="ml-2 text-2xl font-thin text-bluegray-700"
            onClick={() => setIsDatePickerOpen(true)}
          >
            {`${new Date(startDate).toLocaleDateString("sv-SE", {
              month: "short",
              day: "numeric",
            })} - ${new Date(endDate).toLocaleDateString("sv-SE", {
              month: "short",
              day: "numeric",
            })}`}
          </span>
          <span className="mx-2 text-2xl font-thin text-bluegray-700">
            &nbsp;|&nbsp;
          </span>
          <span className="text-2xl font-thin text-bluegray-700">
            {daysBetween(createdAt, startDate)} dagar innan
          </span>
          <span></span>
          {isDatePickerOpen && (
            <DatePicker
              selectsRange={true}
              startDate={stateStart}
              endDate={stateEnd}
              onChange={(update) => {
                const [updateStart, updateEnd] = update;

                setRange(update);

                if (updateStart && updateEnd) {
                  handleUpdate("startDate", updateStart);
                  handleUpdate("endDate", updateEnd);
                  setIsDatePickerOpen(false);
                }
              }}
              locale={sv}
              calendarStartDay={1}
              withPortal
              inline
              onClickOutside={() => setIsDatePickerOpen(false)}
            />
          )}
          {status === "NEW" && (
            <button
              type="button"
              className="flex items-center px-4 py-2 ml-8 font-medium text-teal-600 border border-teal-600 rounded"
              onClick={() => handleUpdate("status", "OUT")}
            >
              <ArrowSmRightIcon className="w-6 h-6 mr-2" />
              <span>Lämna ut</span>
            </button>
          )}
          {status === "OUT" && (
            <button
              type="button"
              className="flex items-center px-4 py-2 ml-8 font-medium text-teal-600 border border-teal-600 rounded"
              onClick={() => handleUpdate("status", "RETURNED")}
            >
              <ArrowSmLeftIcon className="w-6 h-6 mr-2" />
              <span>Ta Emot</span>
            </button>
          )}

          {notes && (
            <AnnotationIcon
              className="w-6 h-6 ml-8 text-teal-600 cursor-pointer"
              onClick={() => setIsNotesDialogOpen(true)}
            />
          )}

          <div className="relative flex items-center flex-none ml-auto">
            <div className="flex items-center gap-2 mr-4">
              <span
                className={`w-3 h-3 ${
                  isNewPrice() ? "bg-green-500" : "bg-red-500"
                } rounded-full`}
              ></span>
              <span
                className={`${
                  isNewPrice() ? "text-green-500" : "text-red-500"
                }`}
              >
                {data.createdAt.split("T")[0]}
              </span>
            </div>
            <BookingDetailsMenu
              handleNotesClick={() => setIsNotesDialogOpen(true)}
              handleStatusClick={() => setIsStatusDialogOpen(true)}
              handleDownloadClick={() => downloadBookingPdf()}
              notes={notes}
            />
          </div>
        </div>
        <div className="flex items-center">
          {firstRowItems.map((item) => (
            <HeaderItem
              key={item.rowKey}
              {...item}
              data={data}
              handleUpdate={handleUpdate}
            />
          ))}
        </div>
        <div className="flex items-center mt-4">
          {secondRowItems.map((item) => (
            <HeaderItem
              key={item.rowKey}
              {...item}
              data={data}
              handleUpdate={handleUpdate}
            />
          ))}
        </div>
      </div>
      <NotesDialog
        isOpen={isNotesDialogOpen}
        closeModal={handleNotesDialogClose}
        bookingId={bookingId}
        notes={notes}
      />
      <BookingStatusDialog
        isOpen={isStatusDialogOpen}
        closeModal={handleStatusDialogClose}
        status={status}
      />
    </>
  );
};

export default BookingDetailsHeader;
