import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
const DeleteDialog = ({ isOpen, closeModal, confirmText }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-bluegray-800 opacity-70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="flex items-start p-6">
                <div className="flex items-center p-3 mr-3 bg-red-100 rounded-full">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="text-xl text-red-600"
                  />
                </div>
                <div className="flex flex-col">
                  <Dialog.Title
                    as="h3"
                    className="flex items-center text-lg font-medium leading-6"
                  >
                    Bekräfta borttagning
                  </Dialog.Title>
                  <div className="mt-2">
                    <p
                      className="text-sm text-bluegray-600"
                      dangerouslySetInnerHTML={{ __html: confirmText }}
                    ></p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end px-6 py-3 bg-gray-50">
                <button
                  type="button"
                  className="px-4 py-2 text-base font-medium border border-gray-300 rounded"
                  onClick={() => closeModal()}
                >
                  Avbryt
                </button>
                <button
                  onClick={() => closeModal(true)}
                  type="button"
                  className="px-4 py-2 ml-2 text-base font-medium text-white bg-red-600 rounded"
                >
                  Ta bort
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteDialog;
