import { Menu } from "@headlessui/react";
import {
  AnnotationIcon,
  DocumentDownloadIcon,
  DotsVerticalIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import React from "react";

const BookingDetailsMenu = ({
  handleDownloadClick,
  handleNotesClick,
  handleStatusClick,
  notes,
}) => {
  return (
    <Menu>
      <Menu.Button>
        <DotsVerticalIcon className="w-6 h-6" />
      </Menu.Button>
      <Menu.Items className="absolute right-0 z-20 w-auto bg-white border border-gray-300 rounded shadow-lg top-10 min-w-max">
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={handleStatusClick}
              className="flex items-center justify-start w-full p-3 flex-nowrap hover:bg-gray-100"
            >
              <RefreshIcon className="flex-none w-4 h-4 mr-2" />
              <span>Byt status</span>
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={handleNotesClick}
              className="flex items-center justify-start w-full p-3 border-t border-gray-300 flex-nowrap hover:bg-gray-100"
            >
              <AnnotationIcon className="flex-none w-4 h-4 mr-2" />
              <span>{`${notes ? "Redigera" : "Lägg till"} kommentar`}</span>
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={handleDownloadClick}
              className="flex items-center justify-start w-full p-3 border-t border-gray-300 flex-nowrap hover:bg-gray-100"
            >
              <DocumentDownloadIcon className="flex-none w-4 h-4 mr-2" />
              <span>Ladda ner</span>
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export default BookingDetailsMenu;
