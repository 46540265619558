import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const NotesDialog = ({ isOpen, closeModal, bookingId, notes }) => {
  const [isEdit, setIsEdit] = useState(false);

  const { register, handleSubmit, setFocus, reset } = useForm();

  const handleKeyDown = (event) => {
    const { which, shiftKey } = event;

    if (which === 27) setIsEdit(false);

    if (which === 13 && !shiftKey) {
      handleSubmit((data) => {
        closeModal(data);
        setIsEdit(false);
      })();
    }
  };

  const handleClick = () => {
    if (isEdit) return;

    setIsEdit(true);
    reset({ notes: notes });
    setTimeout(() => {
      setFocus("notes");
    }, 10);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsEdit(false);
    }, 100);
  };

  useEffect(() => {
    if (!notes) {
      setIsEdit(true);
      setTimeout(() => {
        if (isOpen) {
          setFocus("notes");
        }
      }, 250);
    } else {
      setIsEdit(false);
    }
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-bluegray-800 opacity-70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="div"
                className="flex items-center p-4 text-2xl font-thin border-b border-gray-300"
              >
                <h3>{`Kommentar för bokning #${bookingId}`}</h3>
                <button
                  type="button"
                  className="ml-auto text-gray-400"
                  onClick={() => closeModal()}
                >
                  <XIcon className="w-4 h-4" />
                </button>
              </Dialog.Title>
              <div className="flex flex-col p-4 space-y-4">
                {!isEdit && (
                  <p
                    tabIndex="-1"
                    className="whitespace-pre-line cursor-pointer hover:text-teal-600"
                    onClick={() => handleClick()}
                  >
                    {notes
                      ? notes
                      : "Det finns ingen kommentar tryck här för att skapa en."}
                  </p>
                )}
                {isEdit && (
                  <form
                    className="relative"
                    onSubmit={handleSubmit((data) => {
                      closeModal(data);
                      setIsEdit(false);
                    })}
                  >
                    <textarea
                      {...register("notes")}
                      onBlur={() => handleBlur()}
                      onKeyPress={handleKeyDown}
                      rows="8"
                      className="w-full h-auto pr-12 border border-gray-300 rounded-lg focus:outline-none focus:border-transparent focus:ring-teal-600 focus:ring-opacity-50"
                    ></textarea>
                    <button
                      type="submit"
                      className="absolute px-1 py-1 mt-2 text-sm font-medium text-gray-300 border border-gray-200 rounded right-2"
                    >
                      ENT
                    </button>
                  </form>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NotesDialog;
