import React from "react";

const CustomInput = ({
  label,
  placeholder,
  type = "text",
  inputClasses,
  containerClasses,
  labelClasses,
  register,
  name,
  errors,
}) => {
  return (
    <div className={`flex flex-1 flex-col ${containerClasses}`}>
      {label && (
        <label className={`font-medium ${labelClasses}`} htmlFor={name}>
          {label}
        </label>
      )}
      {type === "text" && (
        <input
          className={`mt-1 flex-1 rounded-lg border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent focus:ring-opacity-50 ${inputClasses} ${
            errors[name]?.message && "border-red-300"
          }`}
          type={type}
          {...(register && register(name))}
          placeholder={placeholder}
        />
      )}
      {type === "textarea" && (
        <textarea
          className={`mt-1 flex-1 rounded-lg border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent focus:ring-opacity-50 ${inputClasses} ${
            errors[name]?.message && "border-red-300"
          }`}
          {...(register && register(name))}
          placeholder={placeholder}
        ></textarea>
      )}
      {errors[name] && (
        <span className="text-red-500">{errors[name].message}</span>
      )}
    </div>
  );
};

export default CustomInput;
