import isToday from "../utils/isToday";
import {
  RefreshIcon,
  ArrowSmRightIcon,
  GlobeAltIcon,
  ArrowSmLeftIcon,
  BadgeCheckIcon,
} from "@heroicons/react/outline";

// eslint-disable-next-line import/no-anonymous-default-export
export default (status, date) => {
  let copiedStatus = status;

  if (copiedStatus === "NEW" && isToday(date)) copiedStatus = "OUTGOING";

  if (copiedStatus === "OUT" && isToday(date)) copiedStatus = "INCOMING";

  switch (copiedStatus) {
    case "NEW":
      return RefreshIcon;
    case "OUTGOING":
      return ArrowSmRightIcon;
    case "OUT":
      return GlobeAltIcon;
    case "INCOMING":
      return ArrowSmLeftIcon;
    case "RETURNED":
      return BadgeCheckIcon;
    default:
      break;
  }
};
