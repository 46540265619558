import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [state, setState] = useState({
    user: null,
    token: null,
    isAuthLoading: true,
  });

  useEffect(() => {
    checkAuth();
  }, []);

  const changeAuth = (token) => {
    let user = null;

    if (token) {
      localStorage.setItem("token", token);
      user = jwt_decode(token);
    } else {
      localStorage.removeItem("token");
      user = null;
    }

    setState({ ...state, user, token, isAuthLoading: false });
  };

  const checkAuth = () => {
    if (state.user && isTokenValid(state.token)) {
      changeAuth(state.token);
      return true;
    }

    const storedToken = localStorage.getItem("token");

    if (storedToken && isTokenValid(storedToken)) {
      changeAuth(storedToken);
      return true;
    }

    changeAuth(null);
    return false;
  };

  const isTokenValid = (token) => {
    const decoded = jwt_decode(token);

    return decoded && new Date(decoded.exp * 1000) > new Date();
  };

  const { user, token, isAuthLoading } = state;

  return (
    <AuthContext.Provider value={{ user, token, isAuthLoading, changeAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
