import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import routes from "../../utils/routes";
import { AppContext } from "../../context/AppContext";
import TopBarSearch from "./TopBarSearch";
import TopBarUserMenu from "./TopBarUserMenu";

const Topbar = ({ path }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const params = useParams();

  const { menuOpen, setMenuOpen } = useContext(AppContext);

  useEffect(() => {
    createBreadcrumbs({ path, params });
  }, [path]);

  const createBreadcrumbs = (route) => {
    const crumbs = routes
      .filter(({ path }) => route.path.includes(path))
      .map(({ path, title, ...rest }) => ({
        path: Object.keys(route.params).length
          ? Object.keys(route.params).reduce(
              (path, param) => path.replace(`:${param}`, route.params[param]),
              path
            )
          : path,
        title: route.params[title] || title,
        ...rest,
      }));

    setBreadcrumbs(crumbs);
  };

  return (
    <div className="flex items-center justify-between p-4 bg-white border-b border-gray-300 shadow-lg">
      <div className="flex items-center flex-none mr-4 font-light">
        {breadcrumbs.map(({ title, path }, i) => (
          <span key={i} className="flex items-center">
            <Link
              to={path}
              className={`${
                i === breadcrumbs.length - 1 ? "text-teal-500" : "text-gray-900"
              }`}
              dangerouslySetInnerHTML={{ __html: title }}
            ></Link>
            {i !== breadcrumbs.length - 1 && <span className="mx-1">/</span>}
          </span>
        ))}
      </div>
      <TopBarSearch />
      <TopBarUserMenu />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-none w-6 h-6 ml-4 xl:hidden"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M4 6h16M4 12h16m-7 6h7"
        />
      </svg>
    </div>
  );
};

export default Topbar;
