import { Tab } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import AddGearDialog from "./AddGearDialog";
import AddUserDialog from "./AddUserDialog";
import BookingGear from "./BookingGear";
import BookingUser from "./BookingUser";

const BookingDetailsBody = ({ users, gear, handleUpdate }) => {
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isAddGearDialogOpen, setIsAddGearDialogOpen] = useState(false);

  const addUser = (data = null) => {
    if (data) {
      handleUpdate("users", [...users, data]);
      console.log(data);
    }
    setIsAddUserDialogOpen(false);
  };

  const addGear = (data = null) => {
    if (data) {
      handleUpdate("gear", [...gear, data]);
      console.log(data);
    }

    setIsAddGearDialogOpen(false);
  };

  const handleUserEdit = (data, index) => {
    users[index] = data;
    handleUpdate("users", [...users]);
  };

  const handleUserDelete = (index) => {
    users.splice(index, 1);
    handleUpdate("users", [...users]);
  };

  const handleGearEdit = (data, index) => {
    gear[index] = data;
    handleUpdate("gear", [...gear]);
  };

  const handleGearDelete = (data, index) => {
    gear.splice(index, 1);
    handleUpdate("gear", [...gear]);
  };

  return (
    <>
      <Tab.Group
        as="div"
        className="flex flex-col flex-1 w-full mb-4 overflow-hidden border border-gray-300 rounded-b-lg"
      >
        <Tab.List
          as="div"
          className="flex justify-between flex-none bg-gray-100 border-b border-gray-300"
        >
          <Tab className="flex-1 hover:bg-gray-200">
            {({ selected }) => (
              <div
                className={`py-2 border-b-2 ${
                  selected ? "border-teal-600" : "border-transparent"
                }`}
              >
                Användare
              </div>
            )}
          </Tab>
          <Tab className="flex-1 border-r border-gray-300 hover:bg-gray-200">
            {({ selected }) => (
              <div
                className={`py-2 border-b-2 ${
                  selected ? "border-teal-600" : "border-transparent"
                }`}
              >
                Utrustning
              </div>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels className="flex-1 overflow-hidden bg-white">
          <Tab.Panel className="relative flex flex-col w-full h-full overflow-hidden">
            <div className="w-full h-full overflow-auto">
              {users.map((user, index) => (
                <BookingUser
                  key={index}
                  index={index}
                  user={user}
                  handleDelete={handleUserDelete}
                  handleEdit={handleUserEdit}
                />
              ))}
            </div>
            <button
              onClick={() => setIsAddUserDialogOpen(true)}
              type="button"
              className="absolute z-10 w-10 h-10 p-2 bg-teal-600 rounded-full shadow-md right-4 bottom-4"
            >
              <PlusIcon className="w-6 h-6 text-white" />
            </button>
          </Tab.Panel>
          <Tab.Panel className="relative flex flex-col w-full h-full overflow-hidden">
            <div className="w-full h-full overflow-auto">
              {gear.map((gear, index) => (
                <BookingGear
                  key={index}
                  index={index}
                  gear={gear}
                  handleDelete={handleGearDelete}
                  handleEdit={handleGearEdit}
                />
              ))}
            </div>
            <button
              onClick={() => setIsAddGearDialogOpen(true)}
              type="button"
              className="absolute z-10 w-10 h-10 p-2 bg-teal-600 rounded-full shadow-md right-4 bottom-4"
            >
              <PlusIcon className="w-6 h-6 text-white" />
            </button>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <AddUserDialog isOpen={isAddUserDialogOpen} closeModal={addUser} />
      <AddGearDialog isOpen={isAddGearDialogOpen} closeModal={addGear} />
    </>
  );
};

export default BookingDetailsBody;
