import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { gearTypes } from "../../utils/gear-helpers";
import CustomInput from "../CustomInput";
const AddUserDialog = ({ isOpen, closeModal, editData = null }) => {
  const schema = yup.object().shape({
    name: yup.string().required(),
    gearType: yup.string().required(),
    age: yup.string(),
    length: yup.string(),
    weight: yup.string(),
    shoeSize: yup.string(),
    additionalInfo: yup.string(),
  });

  useEffect(() => {
    reset(editData);
  }, [editData]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = (data = null) => {
    reset();
    closeModal(data);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-bluegray-800 opacity-70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <form
              onSubmit={handleSubmit(handleClose)}
              className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >
              <Dialog.Title
                as="h3"
                className="flex items-center p-4 text-2xl font-thin border-b border-gray-300"
              >
                Lägg till användare
              </Dialog.Title>
              <div className="flex flex-col p-4 space-y-4">
                <CustomInput
                  type="text"
                  name="name"
                  label="Namn"
                  register={register}
                  errors={errors}
                />
                <div className="flex flex-col flex-1">
                  <label className="font-medium">Utrustningstyp</label>
                  <select
                    {...register("gearType")}
                    className="flex-1 mt-1 border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent focus:ring-opacity-50"
                  >
                    {gearTypes.map((type) => (
                      <option value={type.value} key={type.text}>
                        {type.text}
                      </option>
                    ))}
                  </select>
                </div>
                <CustomInput
                  type="text"
                  name="age"
                  label="Ålder"
                  register={register}
                  errors={errors}
                />
                <CustomInput
                  type="text"
                  name="length"
                  label="Längd"
                  register={register}
                  errors={errors}
                />
                <CustomInput
                  type="text"
                  name="weight"
                  label="Vikt"
                  register={register}
                  errors={errors}
                />
                <CustomInput
                  type="text"
                  name="shoeSize"
                  label="Skostorlek"
                  register={register}
                  errors={errors}
                />
                <CustomInput
                  type="textarea"
                  name="additionalInfo"
                  label="Övrig Information"
                  register={register}
                  errors={errors}
                />
              </div>

              <div className="flex items-center justify-end p-4 bg-gray-50">
                <button
                  type="button"
                  className="px-4 py-2 text-base font-medium border border-gray-300 rounded"
                  onClick={() => handleClose()}
                >
                  Avbryt
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 ml-2 text-base font-medium text-white bg-teal-600 rounded"
                >
                  {editData ? "Spara" : "Lägg till"}
                </button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddUserDialog;
