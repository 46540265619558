import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [state, setState] = useState({
    menuOpen: false,
  });
  const { menuOpen } = state;

  const setMenuOpen = (menuOpen) => {
    setState({ ...state, menuOpen });
  };

  return (
    <AppContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
