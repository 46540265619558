import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetBookings } from "../../hooks/api/bookings";
import Spinner from "../Spinner";
import Card from "../UI/Card/Card";
import ListItem from "../UI/List/ListItem";
import { PlusIcon } from "@heroicons/react/outline";

const AllBookings = () => {
  const [searchText, setSearchText] = useState("");
  const history = useHistory();

  const { data, isLoading } = useGetBookings();

  const filterFn = (row) => {
    let found = false;
    Object.keys(row).forEach((key) => {
      if (
        row[key] &&
        row[key].toString().toLowerCase().includes(searchText.toLowerCase())
      )
        found = true;
    });

    return found;
  };

  const onCardButtonClick = () => {
    history.push("/bookings/create");
  };

  return (
    <div className="flex flex-1 w-full h-full overflow-hidden">
      {isLoading && <Spinner />}
      {!isLoading && data && (
        <Card
          title="Alla bokningar"
          isSearchActive="true"
          setSearchText={setSearchText}
          searchText={searchText}
          isButtonActive="true"
          buttonText="Skapa bokning"
          ButtonIcon={PlusIcon}
          onClick={onCardButtonClick}
        >
          {data
            .filter((x) => filterFn(x))
            .map((booking) => (
              <ListItem key={booking.bookingId} booking={booking} />
            ))}
        </Card>
      )}
    </div>
  );
};

export default AllBookings;
