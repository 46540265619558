import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import DeleteDialog from "../UI/DeleteDialog";
import AddGearDialog from "./AddGearDialog";

const BookingGear = ({ gear, index, handleDelete, handleEdit }) => {
  const { name, size } = gear;

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleEditClose = (data) => {
    if (data) handleEdit(data, index);

    setIsEditDialogOpen(false);
  };

  const handleDeleteClose = (data) => {
    if (data) handleDelete(index);

    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      <div className="flex flex-wrap w-full h-auto p-4 border-b border-gray-300">
        <div className="flex flex-col flex-1">
          <h3 className="font-bold">Utrustning</h3>
          <p>{name}</p>
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="font-bold">Storlek</h3>
          <p>{size}</p>
        </div>

        <div className="flex items-center ml-auto">
          <PencilAltIcon
            className="w-6 h-6 cursor-pointer hover:text-teal-600"
            onClick={() => setIsEditDialogOpen(true)}
          />
          <TrashIcon
            onClick={() => setIsDeleteDialogOpen(true)}
            className="w-6 h-6 ml-4 cursor-pointer hover:text-red-700"
          />
        </div>
      </div>
      <AddGearDialog
        isOpen={isEditDialogOpen}
        editData={{
          name,
          size,
        }}
        closeModal={handleEditClose}
      />
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        closeModal={handleDeleteClose}
        confirmText={`<span>
            Är du säker på att du vill ta bort följande utrustning <strong>${name}</strong>?
          </span>`}
      />
    </>
  );
};

export default BookingGear;
